import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MainService } from '../../../services/main.service';
import * as _ from 'lodash';
import { evaluate } from 'mathjs';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { AlertService } from '../../../../services/alertService/alert-service.service';

@Component({
  selector: 'liquidacion-detalle',
  templateUrl: './liquidacion-detalle.component.html',
  styleUrls: ['./liquidacion-detalle.component.scss'],
})
export class LiquidacionDetalleComponent implements OnInit {
  @Input() available: boolean;
  /** Referencia al dialogo del detalle de la liquidacion */
  @Input() dialogRef: any;
  /** Indica si se esta creando una liquidacion*/
  @Input() createMode: boolean; //false implica que esta en modo editar
  /** Indica si la liquidacion tiene nominacion */
  @Input() conNominacion: boolean;
  /** Viaje asociado a la liquidacion */
  @Input('viaje') viaje: any;
  /** Liquidacion actual */
  @Input('liquidacion') liquidacion: any;
  /** Servicios establecidos en proforma */
  @Input('services') servicesProforma: any;
  /** Puerto establecido en proforma */
  @Input('puerto') puerto: any;  
  /** Terminal establecida en proforma */
  @Input('terminal') terminal: any;   
  /** Evento que indica si hubo un cambio en la BD sobre liquidaciones*/
  @Output() cambioEnLiquidaciones: EventEmitter<any> = new EventEmitter<any>();

  /** Formuloario reactivo de liquidacion */
  public liquidacionForm: FormGroup;
  /** Modo actual del detalle de liquidacion */
  public mode: string = '';
  /** Puertos en la BD */
  public ports: any = [];
  /** Terminales en la BD */
  public terminals: any = [];
  /** Terminales filtradas por el puerto escogido */
  public terminalsByPort = [];
  /** Buques en la BD */
  public vessels: any = [];
  /** Parametros del sistema traidos de la BD */
  public parametrosSistema: any = [];
  /** Factores de arqueo traidos de la BD */
  public factoresArqueo: any = [];
  /** Servicios de la BD */
  public servicios: any = [];
  /** Servicios en la liquidacion */
  public serviciosLiquidacion: any = [];
  /** Servicios del puerto escogido */
  public serviciosPuerto: any = [];
  /** Datos de servicios filtrados por la terminal escogida */
  public serviciosAgregar: any = [];
  /** Buque asociado a la liquidacion */
  public selectedVessel: any;
  /** Empresas traidas de la BD */
  public businesses: any = [];
  /** Indica si el usuario ya busco servicios */
  public buscoServicios: boolean = false;
  /** Valor total de todos los servicios sumados */
  public valorTotal: string = '';
  /** Operador del servicio a agreagar */
  public operadorServicioAgregar: string = '';
  /** Datos del servicio que se va a agregar a la liquidacon */
  public servicioAAgregar: any = {
    cantidad: 0,
    valor: 0,
    tarifa: '',
    facturaRecibida: false,
    valorFormateado: '0',
  };
  /** Indica si algo en el modulo se esta cargando */
  public cargando: boolean = true;

  /** Referencia al la seleccion del servicio a agreagar */
  @ViewChild('servicioAgregar', { static: true }) servicioAgregar: ElementRef;
  /** Referencia a la cantidad del servicio a agregar */
  @ViewChild('cantidadAgregarServicio', { static: true }) cantidadAgregarServicio: ElementRef;
  /** Refgerencia al campo del valor del servicio */
  @ViewChild('valorAgregarServicio', { static: true }) valorAgregarServicio: ElementRef;
  /** Referencia al select de la terminal del servicio a agregar */
  @ViewChild('terminalAgregarServicio', { static: true }) terminalAgregarServicio: ElementRef;
  /** Referencia al checkbox de las facturas recibidas del servicio a agregar */
  @ViewChild('facturaRecibidaCheckBox', { static: true }) facturaRecibidaCheckBox: ElementRef;

  public permisosUsuario: any;
  public usuario: any = JSON.parse(localStorage.getItem('usuario'));

  constructor(private mainService: MainService, private router: Router, private alertService: AlertService) {}

  ngOnInit() {
    this.obtenerPermisos();
    this.configurarModoEInizializarFormulario();
    this.setConnectedInformation();
    this.setTerminalAndPort();
    this.calculateHoursAnchorage();
    this.calculateHoursDockage();
  }

  // format information of prospectos for liquidation: ata, atb, atc, atd
  setConnectedInformation() {
    const prospectosToForm = ['ata', 'atb', 'atc', 'atd'];
    const prospectos = this.viaje.recaladas[0].prospectos;

    prospectosToForm.forEach((prospectoToForm) => {
      if (!prospectos[prospectoToForm]) return;
      const date = new Date(prospectos[prospectoToForm]);

      const dia = date.getUTCDate().toString().padStart(2, '0');
      const mes = (date.getUTCMonth() + 1).toString().padStart(2, '0');
      const anio = date.getUTCFullYear();

      const hora = date.getUTCHours().toString().padStart(2, '0');
      const minutos = date.getUTCMinutes().toString().padStart(2, '0');

      this.liquidacionForm.patchValue({
        [`${prospectoToForm}Date`]: `${anio}-${mes}-${dia}`,
        [`${prospectoToForm}Time`]: `${hora}:${minutos}`,
      });
    });
  }

  setTerminalAndPort() {
    if(!this.puerto || !this.terminal)
    this.liquidacionForm.patchValue({
      port: this.viaje.port.id || '',
      terminal: this.viaje.recaladas[0].terminal.id || '',
      vessel: this.viaje.vessel.id || '',
    });
  }

  /**
   * Determina si el usuario puede acceder a este módulo y sus permisos
   */
  obtenerPermisos() {
    this.mainService.get(`api/rol/${this.usuario.tipo}`).subscribe((res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.liquidaciones === 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al módulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      } else {
        this.initializeData();
      }
    });
  }

  /**
   * Configura el modo actual del detalle e inicializa el detalle segun el modo
   */
  configurarModoEInizializarFormulario() {
    if (this.createMode) {
      this.mode = 'Crear';
      if (!this.conNominacion) {
        this.initializeFormCreateSinNominacion();
      } else {
        this.initializeFormCreateConNominacion();
      }
    } else {
      this.mode = 'Editar';
      this.initializeFormEditar();
    }
  }

  /**
   * Inincializa los datos de la BD
   */
  initializeData() {
    const vesselObservable = this.mainService.get('api/buque?activo=true');
    const portObservable = this.mainService.get('api/puerto?activo=true');
    const terminalObservable = this.mainService.get('api/terminal?activo=true');
    const businessObservable = this.mainService.get('api/empresa_sql?activo=true');
    const parametrosSistemaObservable = this.mainService.get('api/parametro_sistema');
    const factoresArqueoObservable = this.mainService.get('api/factor_arqueo');
    const serviciosObservable = this.mainService.get('api/tarifa?activo=true');

    forkJoin([
      vesselObservable,
      portObservable,
      terminalObservable,
      businessObservable,
      parametrosSistemaObservable,
      factoresArqueoObservable,
      serviciosObservable,
    ]).subscribe(([vessels, ports, terminals, businesses, parametrosSistema, factoresArqueo, servicios]) => {
      // vessels
      this.vessels = _.orderBy(vessels, ['BUQ_NOMBRE']);
      if (this.viaje && this.createMode)
        this.selectedVessel = this.vessels.find((element) => element.BUQ_ID == this.viaje.vessel.id);
      if (this.liquidacion && !this.createMode)
        this.selectedVessel = this.vessels.find((element) => element.BUQ_ID == this.liquidacion.vessel);

      this.ports = ports.filter((port) => port.PUE_ID != 0);

      // terminals

      this.terminals = _.orderBy(terminals, ['TER_NOMBRE']);
      this.terminals = this.terminals.filter((terminal) => terminal.TER_ID != 0);
      this.terminalsByPort = this.terminals;
      this.onChangePort();

      // businesses and others

      this.businesses = _.orderBy(businesses, ['EMP_NOMBRE']);
      this.parametrosSistema = parametrosSistema;
      this.factoresArqueo = _.orderBy(factoresArqueo, ['rangoGRTMax'], ['asc']);
      this.servicios = _.orderBy(servicios, ['nombreServicio']);

      this.cargando = false;

      this.onBotonServicios(false);
    });
  }

  /**
   * Inicializa el formulairo cuando la liquidacion no tiene nominacion y el modo es crear
   */
  initializeFormCreateSinNominacion() {
    this.liquidacionForm = new FormGroup({
      nv: new FormControl({ value: '', disabled: false }),
      ataDate: new FormControl({ value: '', disabled: false }, Validators.required),
      ataTime: new FormControl({ value: '', disabled: false }, Validators.required),
      atbDate: new FormControl({ value: '', disabled: false }, Validators.required),
      atbTime: new FormControl({ value: '', disabled: false }, Validators.required),
      atcDate: new FormControl({ value: '', disabled: false }, Validators.required),
      atcTime: new FormControl({ value: '', disabled: false }, Validators.required),
      atdDate: new FormControl({ value: '', disabled: false }, Validators.required),
      atdTime: new FormControl({ value: '', disabled: false }, Validators.required),
      hoursAnchorage: new FormControl({ value: '', disabled: false }, Validators.required),
      hoursDockage: new FormControl({ value: '', disabled: false }, Validators.required),
      port: new FormControl({ value: '', disabled: false }, Validators.required),
      terminal: new FormControl({ value: '', disabled: false }, Validators.required),
      vessel: new FormControl({ value: '', disabled: false }, Validators.required),
      load: new FormControl({ value: '', disabled: false }, Validators.pattern('[0-9]*[.]?[0-9]*')),
      idRecalada: new FormControl({ value: this.viaje.recaladas[0]._id, disabled: false }),
    });
  }

  /**
   * Inicializa el formulario cuando la liquidacion tiene nominacion y el modo es crear
   */
  initializeFormCreateConNominacion() {
    this.formatViajeInput();

    this.liquidacionForm = new FormGroup({
      nv: new FormControl({ value: this.viaje.nv ? this.viaje.nv : '', disabled: false }),
      ataDate: new FormControl(
        { value: this.viaje.ataDate ? this.viaje.ataDate : '', disabled: false },
        Validators.required
      ),
      ataTime: new FormControl(
        { value: this.viaje.ataTime ? this.viaje.ataTime : '', disabled: false },
        Validators.required
      ),
      atbDate: new FormControl(
        { value: this.viaje.atbDate ? this.viaje.atbDate : '', disabled: false },
        Validators.required
      ),
      atbTime: new FormControl(
        { value: this.viaje.atbTime ? this.viaje.atbTime : '', disabled: false },
        Validators.required
      ),
      atcDate: new FormControl(
        { value: this.viaje.atcDate ? this.viaje.atcDate : '', disabled: false },
        Validators.required
      ),
      atcTime: new FormControl(
        { value: this.viaje.atcTime ? this.viaje.atcTime : '', disabled: false },
        Validators.required
      ),
      atdDate: new FormControl(
        { value: this.viaje.atdDate ? this.viaje.atdDate : '', disabled: false },
        Validators.required
      ),
      atdTime: new FormControl(
        { value: this.viaje.atdTime ? this.viaje.atdTime : '', disabled: false },
        Validators.required
      ),
      hoursAnchorage: new FormControl({ value: '', disabled: false }, Validators.required),
      hoursDockage: new FormControl({ value: '', disabled: false }, Validators.required),
      port: new FormControl({ value: this.puerto ? this.puerto : this.viaje.port ? this.viaje.port : '', disabled: false }, Validators.required),
      terminal: new FormControl(
        { value: this.terminal ? this.terminal : this.viaje.terminal ? this.viaje.terminal : '', disabled: false },
        Validators.required
      ),
      vessel: new FormControl(
        { value: this.viaje.vessel ? this.viaje.vessel.id : '', disabled: false },
        Validators.required
      ),
      load: new FormControl({
        value: this.viaje.recaladas[0].informacionExtra.load ? this.viaje.recaladas[0].informacionExtra.load : '',
        disabled: false,
      }),
      idRecalada: new FormControl({ value: this.viaje.recaladas[0]._id, disabled: false }),
    });

    this.calculateHoursAnchorage();
    this.calculateHoursDockage();
  }

  /**
   * Inicializa el formulario en el modo de editar
   */
  initializeFormEditar() {
    this.formatLiquidacionInput();
    this.liquidacionForm = new FormGroup({
      nv: new FormControl({ value: this.liquidacion.nv ? this.liquidacion.nv : '', disabled: false }),
      ataDate: new FormControl(
        { value: this.liquidacion.ataDate ? this.liquidacion.ataDate : '', disabled: false },
        Validators.required
      ),
      ataTime: new FormControl(
        { value: this.liquidacion.ataTime ? this.liquidacion.ataTime : '', disabled: false },
        Validators.required
      ),
      atbDate: new FormControl(
        { value: this.liquidacion.atbDate ? this.liquidacion.atbDate : '', disabled: false },
        Validators.required
      ),
      atbTime: new FormControl(
        { value: this.liquidacion.atbTime ? this.liquidacion.atbTime : '', disabled: false },
        Validators.required
      ),
      atcDate: new FormControl(
        { value: this.liquidacion.atcDate ? this.liquidacion.atcDate : '', disabled: false },
        Validators.required
      ),
      atcTime: new FormControl(
        { value: this.liquidacion.atcTime ? this.liquidacion.atcTime : '', disabled: false },
        Validators.required
      ),
      atdDate: new FormControl(
        { value: this.liquidacion.atdDate ? this.liquidacion.atdDate : '', disabled: false },
        Validators.required
      ),
      atdTime: new FormControl(
        { value: this.liquidacion.atdTime ? this.liquidacion.atdTime : '', disabled: false },
        Validators.required
      ),
      hoursAnchorage: new FormControl(
        { value: this.liquidacion.hoursAnchorage ? this.liquidacion.hoursAnchorage : '', disabled: false },
        Validators.required
      ),
      hoursDockage: new FormControl(
        { value: this.liquidacion.hoursDockage ? this.liquidacion.hoursDockage : '', disabled: false },
        Validators.required
      ),
      port: new FormControl(
        { value: this.liquidacion.port ? this.liquidacion.port : '', disabled: false },
        Validators.required
      ),
      terminal: new FormControl(
        { value: this.liquidacion.terminal ? this.liquidacion.terminal : '', disabled: false },
        Validators.required
      ),
      vessel: new FormControl(
        { value: this.liquidacion.vessel ? this.liquidacion.vessel : '', disabled: false },
        Validators.required
      ),
      load: new FormControl(
        { value: this.liquidacion.load ? this.liquidacion.load : '', disabled: false },
        Validators.pattern('[0-9]*[.]?[0-9]*')
      ),
      idRecalada: new FormControl({ value: this.viaje.recaladas[0]._id, disabled: false }),
    });
  }

  allReceivedInvoicesCount() {
    this.allReceivedInvoices = this.serviciosLiquidacion.every((service) => service.facturaRecibida);
  }

  /**
   * Arregla el formato de liquidacion de la BD al formato aceptado por el formulario reactivo
   */
  formatLiquidacionInput() {
    if (this.liquidacion.ata) {
      this.liquidacion.ata = new Date(this.liquidacion.ata);
      this.liquidacion.ataDate = `${this.liquidacion.ata.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.liquidacion.ata.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.liquidacion.ata.getUTCDate())}`;
      this.liquidacion.ataTime = `${this.toMinimumTwoDigitNumber(
        this.liquidacion.ata.getUTCHours()
      )}:${this.toMinimumTwoDigitNumber(this.liquidacion.ata.getUTCMinutes())}`;
    }

    if (this.liquidacion.atb) {
      this.liquidacion.atb = new Date(this.liquidacion.atb);
      this.liquidacion.atbDate = `${this.liquidacion.atb.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.liquidacion.atb.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.liquidacion.atb.getUTCDate())}`;
      this.liquidacion.atbTime = `${this.toMinimumTwoDigitNumber(
        this.liquidacion.atb.getUTCHours()
      )}:${this.toMinimumTwoDigitNumber(this.liquidacion.atb.getUTCMinutes())}`;
    }

    if (this.liquidacion.atc) {
      this.liquidacion.atc = new Date(this.liquidacion.atc);
      this.liquidacion.atcDate = `${this.liquidacion.atc.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.liquidacion.atc.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.liquidacion.atc.getUTCDate())}`;
      this.liquidacion.atcTime = `${this.toMinimumTwoDigitNumber(
        this.liquidacion.atc.getUTCHours()
      )}:${this.toMinimumTwoDigitNumber(this.liquidacion.atc.getUTCMinutes())}`;
    }

    if (this.liquidacion.atd) {
      this.liquidacion.atd = new Date(this.liquidacion.atd);
      this.liquidacion.atdDate = `${this.liquidacion.atd.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.liquidacion.atd.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.liquidacion.atd.getUTCDate())}`;
      this.liquidacion.atdTime = `${this.toMinimumTwoDigitNumber(
        this.liquidacion.atd.getUTCHours()
      )}:${this.toMinimumTwoDigitNumber(this.liquidacion.atd.getUTCMinutes())}`;
    }
  }

  /**  return serviceLiquidation.every((service) => service.facturaRecibida);

   * Cambia las terminales filtradas por puerto segun el puerto escogido
   */
  onChangePort() {
    // Id del puerto escogido por el usaurio
    const userInput = this.liquidacionForm.get('port').value;
    // Puerto seleccionado por el usuario
    const selectedPort = this.ports.find((p) => {
      return p.PUE_ID == userInput;
    });
    this.terminalsByPort = this.terminals.filter((t) => {
      return t.PUE_ID == selectedPort.PUE_ID;
    });
  }

  /**
   * Cierra el dialogo del detalle de liquidacion
   */
  closeDialog() {
    this.dialogRef.close();
  }

  private allReceivedInvoices = true;
  /**
   * Verifica que la informacion sea correcta y envia los datos del formulario a la BD
   */
  async onSubmit() {
    let ata;
    let atb;
    let atc;
    let atd;

    if (
      this.liquidacionForm.value.ataDate &&
      this.liquidacionForm.value.ataTime &&
      this.liquidacionForm.value.atbDate &&
      this.liquidacionForm.value.atbTime &&
      this.liquidacionForm.value.atcDate &&
      this.liquidacionForm.value.atcTime &&
      this.liquidacionForm.value.atdDate &&
      this.liquidacionForm.value.atdTime
    ) {
      const ataDate = this.liquidacionForm.value.ataDate.split('-');
      const ataTime = this.liquidacionForm.value.ataTime.split(':');
      ata = Date.UTC(ataDate[0], ataDate[1] - 1, ataDate[2], ataTime[0], ataTime[1]);

      const atbDate = this.liquidacionForm.value.atbDate.split('-');
      const atbTime = this.liquidacionForm.value.atbTime.split(':');
      atb = Date.UTC(atbDate[0], atbDate[1] - 1, atbDate[2], atbTime[0], atbTime[1]);

      const atcDate = this.liquidacionForm.value.atcDate.split('-');
      const atcTime = this.liquidacionForm.value.atcTime.split(':');
      atc = Date.UTC(atcDate[0], atcDate[1] - 1, atcDate[2], atcTime[0], atcTime[1]);

      const atdDate = this.liquidacionForm.value.atdDate.split('-');
      const atdTime = this.liquidacionForm.value.atdTime.split(':');
      atd = Date.UTC(atdDate[0], atdDate[1] - 1, atdDate[2], atdTime[0], atdTime[1]);
    }

    const currentModeMin = this.mode.toLocaleLowerCase();

    if (this.liquidacionForm.invalid) {
      Swal.fire({
        title: `No se puede ${currentModeMin} la liquidación`,
        text: 'Hay campos requeridos vacíos o campos con valores incorrectos',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
    } else if (!(ata <= atb && atb <= atc && atc <= atd)) {
      Swal.fire({
        title: `No se puede ${currentModeMin} la liquidación`,
        text: 'Se debe cumplir lo siguiente: (ATA < ATB < ATC < ATD)',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
    } else if (this.serviciosLiquidacion.length == 0 && this.createMode) {
      Swal.fire({
        title: `No se puede ${currentModeMin} la liquidación`,
        text: 'Debe incluirse por lo menos un servicio',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
    } else {
      const formContent = this.liquidacionForm.value;
      const liquidacion = JSON.parse(JSON.stringify(formContent));
      liquidacion.ata = ata;
      liquidacion.atb = atb;
      liquidacion.atc = atc;
      liquidacion.atd = atd;
      liquidacion.nv = liquidacion.nv || this.liquidacion.nv;
      liquidacion.serviciosLiquidacion = [];
      let todasLasFacturasFueronRecibidas = true;
      this.serviciosLiquidacion.forEach((element) => {
        const servicioAAgregar = {
          cantidad: element.cantidad,
          valor: element.valor,
          facturaRecibida: element.facturaRecibida,
          tarifa: element.tarifa._id,
        };
        if (todasLasFacturasFueronRecibidas) {
          todasLasFacturasFueronRecibidas = todasLasFacturasFueronRecibidas && element.facturaRecibida;
        }
        liquidacion.serviciosLiquidacion.push(servicioAAgregar);
      });

      let canContinue = true;

      if (!this.allReceivedInvoices && !this.createMode) {
        const resClient = await this.alertService.optionsAlertWarning(
          '¿Estás seguro de que quieres actualizar la liquidación?'
        );
        if (!resClient.value) canContinue = false;
      }

      if (!canContinue) return;

      if (this.createMode) {
        this.mainService.post('api/liquidacion', liquidacion).subscribe(
          (result) => {
            if (result) {
              this.cambioEnLiquidaciones.emit(result);

              Swal.fire('Éxito', 'Se agregó la liquidación exitosamente', 'success').then((result) => {
                if (result.value) {
                  this.closeDialog();
                }
              });
            } else {
              Swal.fire('Error', 'No se pudo agregar la liquidación, verifique la información', 'warning');
            }
          },
          (err) => {
            Swal.fire('Error', err.error.message, 'error');
          }
        );
      } else {
        this.mainService.put('api/liquidacion/' + this.liquidacion._id, liquidacion).subscribe((result) => {
          if (result) {
            this.cambioEnLiquidaciones.emit(result);

            Swal.fire('Éxito', 'Se actualizó la liquidación exitosamente', 'success').then((result) => {
              if (result.value) {
                this.closeDialog();
              }
            });
          } else {
            Swal.fire('Error', 'No se pudo actualizar la liquidación, verifique la información', 'warning');
          }
        });
      }

      const fechaCierrePuerto = todasLasFacturasFueronRecibidas ? new Date() : null;
    }
  }

  /**
   * Calcula las horas de anclaje segun el ata y el atb ingresado por el usuario
   */
  calculateHoursAnchorage() {
    if (
      this.liquidacionForm.value.ataDate &&
      this.liquidacionForm.value.ataTime &&
      this.liquidacionForm.value.atbDate &&
      this.liquidacionForm.value.atbTime
    ) {
      const ataDate = this.liquidacionForm.value.ataDate.split('-');
      const ataTime = this.liquidacionForm.value.ataTime.split(':');
      const ata = Date.UTC(ataDate[0], ataDate[1] - 1, ataDate[2], ataTime[0], ataTime[1]);

      const atbDate = this.liquidacionForm.value.atbDate.split('-');
      const atbTime = this.liquidacionForm.value.atbTime.split(':');
      const atb = Date.UTC(atbDate[0], atbDate[1] - 1, atbDate[2], atbTime[0], atbTime[1]);

      const hoursAnchorage = Math.ceil((atb - ata) / 3600000);
      this.liquidacionForm.controls['hoursAnchorage'].setValue(hoursAnchorage);
    }
  }

  /**
   * Calcula las horas de mueyaje segun el ata y el atd
   */
  calculateHoursDockage() {
    if (
      this.liquidacionForm.value.ataDate &&
      this.liquidacionForm.value.ataTime &&
      this.liquidacionForm.value.atdDate &&
      this.liquidacionForm.value.atdTime
    ) {
      const ataDate = this.liquidacionForm.value.ataDate.split('-');
      const ataTime = this.liquidacionForm.value.ataTime.split(':');
      const ata = Date.UTC(ataDate[0], ataDate[1] - 1, ataDate[2], ataTime[0], ataTime[1]);

      const atdDate = this.liquidacionForm.value.atdDate.split('-');
      const atdTime = this.liquidacionForm.value.atdTime.split(':');
      const atd = Date.UTC(atdDate[0], atdDate[1] - 1, atdDate[2], atdTime[0], atdTime[1]);

      const hoursDockage = Math.ceil((atd - ata) / 3600000);
      this.liquidacionForm.controls['hoursDockage'].setValue(hoursDockage);
    }
  }

  /**
   * Formatea el objeto de viajes traido de la VD al formato aceptado por el formulario
   */
  formatViajeInput() {
    if (this.viaje.arrivalTime) {
      this.viaje.arrivalTime = new Date(this.viaje.arrivalTime);
      this.viaje.arrivalTimeDate = `${this.viaje.arrivalTime.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.arrivalTime.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.arrivalTime.getUTCDate())}`;
      this.viaje.arrivalTimeTime = `${this.toMinimumTwoDigitNumber(
        this.viaje.arrivalTime.getUTCHours()
      )}:${this.toMinimumTwoDigitNumber(this.viaje.arrivalTime.getUTCMinutes())}`;
    }

    if (this.viaje.berthingTime) {
      this.viaje.berthingTime = new Date(this.viaje.berthingTime);
      this.viaje.berthingTimeDate = `${this.viaje.berthingTime.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.berthingTime.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.berthingTime.getUTCDate())}`;
      this.viaje.berthingTimeTime = `${this.toMinimumTwoDigitNumber(
        this.viaje.berthingTime.getUTCHours()
      )}:${this.toMinimumTwoDigitNumber(this.viaje.berthingTime.getUTCMinutes())}`;
    }

    if (this.viaje.completionOpsTime) {
      this.viaje.completionOpsTime = new Date(this.viaje.completionOpsTime);
      this.viaje.completionOpsTimeDate = `${this.viaje.completionOpsTime.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.completionOpsTime.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.completionOpsTime.getUTCDate())}`;
      this.viaje.completionOpsTimeTime = `${this.toMinimumTwoDigitNumber(
        this.viaje.completionOpsTime.getUTCHours()
      )}:${this.toMinimumTwoDigitNumber(this.viaje.completionOpsTime.getUTCMinutes())}`;
    }

    if (this.viaje.sailingTime) {
      this.viaje.sailingTime = new Date(this.viaje.sailingTime);
      this.viaje.sailingTimeDate = `${this.viaje.sailingTime.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.sailingTime.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.sailingTime.getUTCDate())}`;
      this.viaje.sailingTimeTime = `${this.toMinimumTwoDigitNumber(
        this.viaje.sailingTime.getUTCHours()
      )}:${this.toMinimumTwoDigitNumber(this.viaje.sailingTime.getUTCMinutes())}`;
    }

    if (this.viaje.ata) {
      this.viaje.ata = new Date(this.viaje.ata);
      this.viaje.ataDate = `${this.viaje.ata.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.ata.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.ata.getUTCDate())}`;
      this.viaje.ataTime = `${this.toMinimumTwoDigitNumber(
        this.viaje.ata.getUTCHours()
      )}:${this.toMinimumTwoDigitNumber(this.viaje.ata.getUTCMinutes())}`;
    }

    if (this.viaje.atb) {
      this.viaje.atb = new Date(this.viaje.atb);
      this.viaje.atbDate = `${this.viaje.atb.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.atb.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.atb.getUTCDate())}`;
      this.viaje.atbTime = `${this.toMinimumTwoDigitNumber(
        this.viaje.atb.getUTCHours()
      )}:${this.toMinimumTwoDigitNumber(this.viaje.atb.getUTCMinutes())}`;
    }

    if (this.viaje.atc) {
      this.viaje.atc = new Date(this.viaje.atc);
      this.viaje.atcDate = `${this.viaje.atc.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.atc.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.atc.getUTCDate())}`;
      this.viaje.atcTime = `${this.toMinimumTwoDigitNumber(
        this.viaje.atc.getUTCHours()
      )}:${this.toMinimumTwoDigitNumber(this.viaje.atc.getUTCMinutes())}`;
    }

    if (this.viaje.atd) {
      this.viaje.atd = new Date(this.viaje.atd);
      this.viaje.atdDate = `${this.viaje.atd.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.atd.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.atd.getUTCDate())}`;
      this.viaje.atdTime = `${this.toMinimumTwoDigitNumber(
        this.viaje.atd.getUTCHours()
      )}:${this.toMinimumTwoDigitNumber(this.viaje.atd.getUTCMinutes())}`;
    }

    if (this.viaje.ata) {
      this.viaje.ata = new Date(this.viaje.ata);
      this.viaje.ataDate = `${this.viaje.ata.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.ata.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.ata.getUTCDate())}`;
      this.viaje.ataTime = `${this.toMinimumTwoDigitNumber(
        this.viaje.ata.getUTCHours()
      )}:${this.toMinimumTwoDigitNumber(this.viaje.ata.getUTCMinutes())}`;
    }

    if (this.viaje.atb) {
      this.viaje.atb = new Date(this.viaje.atb);
      this.viaje.atbDate = `${this.viaje.atb.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.atb.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.atb.getUTCDate())}`;
      this.viaje.atbTime = `${this.toMinimumTwoDigitNumber(
        this.viaje.atb.getUTCHours()
      )}:${this.toMinimumTwoDigitNumber(this.viaje.atb.getUTCMinutes())}`;
    }

    if (this.viaje.atc) {
      this.viaje.atc = new Date(this.viaje.atc);
      this.viaje.atcDate = `${this.viaje.atc.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.atc.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.atc.getUTCDate())}`;
      this.viaje.atcTime = `${this.toMinimumTwoDigitNumber(
        this.viaje.atc.getUTCHours()
      )}:${this.toMinimumTwoDigitNumber(this.viaje.atc.getUTCMinutes())}`;
    }

    if (this.viaje.atd) {
      this.viaje.atd = new Date(this.viaje.atd);
      this.viaje.atdDate = `${this.viaje.atd.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.atd.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.atd.getUTCDate())}`;
      this.viaje.atdTime = `${this.toMinimumTwoDigitNumber(
        this.viaje.atd.getUTCHours()
      )}:${this.toMinimumTwoDigitNumber(this.viaje.atd.getUTCMinutes())}`;
    }

    if (this.viaje.fechaAnticipo) {
      this.viaje.fechaAnticipo = new Date(this.viaje.fechaAnticipo);
      this.viaje.fechaAnticipo = `${this.viaje.fechaAnticipo.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.fechaAnticipo.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.fechaAnticipo.getUTCDate())}`;
    }

    if (this.viaje.fechaCierrePuerto) {
      this.viaje.fechaCierrePuerto = new Date(this.viaje.fechaCierrePuerto);
      this.viaje.fechaCierrePuerto = `${this.viaje.fechaCierrePuerto.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.fechaCierrePuerto.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.fechaCierrePuerto.getUTCDate())}`;
    }

    if (this.viaje.fechaFacturacion) {
      this.viaje.fechaFacturacion = new Date(this.viaje.fechaFacturacion);
      this.viaje.fechaFacturacion = `${this.viaje.fechaFacturacion.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.fechaFacturacion.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.fechaFacturacion.getUTCDate())}`;
    }
  }

  /**
   * Se encarga de buscar los servicios que apliquen cuando se esta creando una liquidacion y mostrar los servicios de la
   * liquidacion cuando se esta editando la liquidacion
   */
  onBotonServicios(reloadProformedServices: boolean) {
    let tempListFilterlist = [];
    const provServices = [...this.servicesProforma];
    if (this.liquidacionForm.invalid) {
      Swal.fire({
        title: `No se puede buscar los servicios`,
        text: 'Deben llenarse todos los campos',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
    } else if (!reloadProformedServices && this.serviciosPuerto.length === 0) {
      this.buscoServicios = true;
      const serviciosTodosLosPuertos = this.servicios.filter((servicio) => servicio.puerto == '0');
      const serviciosPuertoEscogido = this.servicios.filter(
        (servicio) => servicio.puerto == this.liquidacionForm.get('port').value
      );
      this.serviciosPuerto = serviciosTodosLosPuertos.concat(serviciosPuertoEscogido);
      this.serviciosPuerto = _.orderBy(this.serviciosPuerto, ['nombreServicio']);

      if (this.createMode) {
        tempListFilterlist = [];
      } else {
        this.onChangeVessel(this.liquidacion.vessel);
        this.initializeServiciosLiquidacion();
        tempListFilterlist = [...this.serviciosLiquidacion];
      }
    } else if (reloadProformedServices) {
      tempListFilterlist = provServices;
    }
    this.serviciosLiquidacion = tempListFilterlist;
    this.calcularTotal();
  }

  /**
   * Busca los servicios que apliquen a la terminal actualmente seleccionada
   */
  buscarServiciosQueApliquen() {
    const serviciosTerminal = this.serviciosPuerto.filter(
      (servicio) => servicio.terminal == 0 || servicio.terminal == this.liquidacionForm.get('terminal').value
    );

    serviciosTerminal.forEach((element) => {
      if (
        (element.baseRango == 'HRS_DOCKAGE' && this.liquidacionForm.get('hoursDockage').value >= element.rangoMax) ||
        (element.baseRango == 'HRS_DOCKAGE' &&
          this.liquidacionForm.get('hoursDockage').value <= element.rangoMax &&
          this.liquidacionForm.get('hoursDockage').value > element.rangoMin) ||
        (element.baseRango == 'GRT' &&
          this.selectedVessel.BUQ_GT <= element.rangoMax &&
          this.selectedVessel.BUQ_GT > element.rangoMin) ||
        (element.baseRango == 'DWT' &&
          this.selectedVessel.BUQ_DWT <= element.rangoMax &&
          this.selectedVessel.BUQ_DWT > element.rangoMin) ||
        element.baseRango == 'NA'
      ) {
        const servicioLiquidacion = this.crearServicioLiquidacionPorTarifa(element);

        if (servicioLiquidacion.cantidad) {
          this.serviciosLiquidacion.push(servicioLiquidacion);
        }
      }
    });

    this.calcularTotal();
  }

  crearServicioLiquidacionPorTarifa(tarifa) {
    let servicioLiquidacion = {
      cantidad: 0,
      valor: 0,
      facturaRecibida: false,
      tarifa: tarifa,
    };

    const valorTarifaUSD = this.obtenerValorTarifaUSD(servicioLiquidacion);

    if (tarifa.baseCantidad == 'FIJA') servicioLiquidacion.cantidad = tarifa.valorCantidad;
    else if (tarifa.baseCantidad == 'HRS_ANCHORAGE')
      servicioLiquidacion.cantidad = this.liquidacionForm.get('hoursAnchorage').value;
    // Caso donde tarifa.baseCantidad == 'HRS_DOCKAGE'
    else {
      if (this.liquidacionForm.get('hoursDockage').value <= tarifa.rangoMax) {
        servicioLiquidacion.cantidad = this.liquidacionForm.get('hoursDockage').value - tarifa.rangoMin;
      } else servicioLiquidacion.cantidad = tarifa.rangoMax - tarifa.rangoMin;
    }

    servicioLiquidacion.valor = evaluate(
      this.reemplazarVariablesFormulaTarifa(servicioLiquidacion.cantidad, valorTarifaUSD, tarifa.formula)
    );

    return servicioLiquidacion;
  }

  /**
   * Inicializa el arreglo con los servicios de una liquidacion
   */
  initializeServiciosLiquidacion() {
    this.liquidacion.serviciosLiquidacion.forEach((servicioLiquidacion) => {
      if (!servicioLiquidacion.tarifa.nombreServicio) {
        servicioLiquidacion.tarifa = this.servicios.find((servicio) => servicio._id == servicioLiquidacion.tarifa);
      }

      const servicio = {
        cantidad: servicioLiquidacion.cantidad,
        valor: servicioLiquidacion.valor,
        facturaRecibida: servicioLiquidacion.facturaRecibida,
        tarifa: servicioLiquidacion.tarifa,
      };

      this.serviciosLiquidacion.push(servicio);
    });
  }

  /**
   * Convierte un numero a un string con el numero en minimo 2 digitos
   * @param number Numero que se quiere convertir a un string de 2 digitos
   */
  toMinimumTwoDigitNumber(number) {
    if (number < 10) return `0${number}`;
    else return `${number}`;
  }

  /**
   * Elimina un servicio del arreglo de servicios de la liquidacion
   * @param servicioId identificador en el arreglod de servicios del servicio que se quiere eliminar
   */
  deleteServicio(servicioId) {
    this.serviciosLiquidacion.splice(servicioId, 1);
    this.calcularTotal();
  }

  /**
   * Hace los cambios necesarios cuando uno de los servicios ya agregados se le cambia su cantidad
   */
  servicioChanged(servicioDetails) {
    this.serviciosLiquidacion[servicioDetails.servicioId].cantidad = servicioDetails.servicio.cantidad;
    this.serviciosLiquidacion[servicioDetails.servicioId].valor = servicioDetails.servicio.valor;
    this.allReceivedInvoicesCount();
    this.calcularTotal();
  }

  /**
   * Cambia el buque seleccionado segun el valor seleccionado por el usuario
   * @param idVessel ID del buque
   */
  onChangeVessel(idVessel) {
    this.selectedVessel = this.vessels.find((element) => element.BUQ_ID == idVessel);
  }

  /**
   * Calcula el total del costo de todos los servicios
   */
  calcularTotal() {
    let total = 0;

    this.serviciosLiquidacion.forEach((element) => {
      total += element.valor;
    });

    this.valorTotal = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(
      total
    );
  }

  /**
   * Filtra los servicios que se pueden agregar segun la terminal del servicio a agregar escogida
   * @idTerminal Id de la terminal escogida por el usuario
   */
  onChangeTerminalAgregarServicio(idTerminal) {
    this.serviciosAgregar = this.serviciosPuerto.filter((servicio) => servicio.terminal == idTerminal);
  }

  /**
   * Se cambia la tarifa del servicio que se va a agregar y el operador del servicio
   * @idServicio ID del servicio escogido por el usuario
   */
  onChangeServicioAgregarServicio(idServicio) {
    this.servicioAAgregar.tarifa = this.serviciosAgregar.find((element) => element._id == idServicio);
    this.operadorServicioAgregar = this.servicioAAgregar.tarifa.operador;
  }

  /**
   * Agrega un servicio al arreglo de servicios de la liquidacion
   */
  onAgregarServicio() {
    const servicioAgregarInput = this.servicioAgregar.nativeElement;
    const cantidadAgregarInput = this.cantidadAgregarServicio.nativeElement;
    const valorAgregarServicioInput = this.valorAgregarServicio.nativeElement;
    const terminalAgregarServicioInput = this.terminalAgregarServicio.nativeElement;

    if (!servicioAgregarInput.value || !cantidadAgregarInput.value) {
      Swal.fire({
        title: `No se puede agregar el servicio`,
        text: 'Se debe escoger un servicio e ingresar una cantidad',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
    } else {
      const servicioLiquidacion = {
        cantidad: this.servicioAAgregar.cantidad,
        valor: this.servicioAAgregar.valor,
        facturaRecibida: this.servicioAAgregar.facturaRecibida,
        tarifa: this.servicioAAgregar.tarifa,
      };

      this.serviciosLiquidacion.push(servicioLiquidacion);
      servicioAgregarInput.value = '';
      cantidadAgregarInput.value = '';
      valorAgregarServicioInput.value = '';
      this.operadorServicioAgregar = '';
      terminalAgregarServicioInput.value = '';
      this.servicioAAgregar = {
        cantidad: 0,
        valor: 0,
        tarifa: '',
        facturaRecibida: false,
        valorFormateado: '0',
      };

      this.calcularTotal();
    }
  }

  /**
   * Calcula el valor del servicio a agregar segun la cantidad ingresada
   * @param valorIngresado Cantidad del servicio ingresada por el usuario
   */
  onChangeCantidad(valorIngresado) {
    let valorTarifaUSD = this.obtenerValorTarifaUSD(this.servicioAAgregar);

    this.servicioAAgregar.cantidad = valorIngresado;
    this.servicioAAgregar.valor = evaluate(
      this.reemplazarVariablesFormulaTarifa(valorIngresado, valorTarifaUSD, this.servicioAAgregar.tarifa.formula)
    );
    this.servicioAAgregar.valorFormateado = this.formatearNumero(this.servicioAAgregar.valor);

    this.calcularTotal();
  }

  /**
   * Convierte un numero a su version en formato separado por comas
   * @param valor Valor del numero que se quiere formatear
   */
  formatearNumero(valor) {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(valor);
  }

  /**
   * Calcula el valor de la tarifa en dolares para un servicio
   * @param servicio Servicio del cual se quiere calcular el valor en dolares
   */
  obtenerValorTarifaUSD(servicio) {
    let valorTarifaUSD = 0;
    if (servicio.tarifa.tipoTarifa == 'DINAMICA' && servicio.tarifa.tarifaAplicarUSD) {
      let formulaTarifaUSD = servicio.tarifa.tarifaAplicarUSD.slice();
      this.parametrosSistema.forEach((element) => {
        formulaTarifaUSD = formulaTarifaUSD.replace(element.nombre, element.valor);
      });
      formulaTarifaUSD = formulaTarifaUSD.replace('COP', servicio.tarifa.tarifaCOP);

      valorTarifaUSD = evaluate(formulaTarifaUSD);
    } else if (servicio.tarifa.tarifaAplicarUSD) {
      valorTarifaUSD = evaluate(servicio.tarifa.tarifaAplicarUSD);
    }

    return valorTarifaUSD;
  }

  /**
   * Cambia los nombres de las variables en la formula de una tarifa a sus valores numericos
   * @param cantidad Cantidad del servicio ingresada
   * @param valorTarifaUSD Valor de la tarifa en dolares
   * @param formulaTarifa Formula para calcular el costo de un servicio
   */
  reemplazarVariablesFormulaTarifa(cantidad, valorTarifaUSD, formulaTarifa) {
    let formulaServicio;
    if (formulaTarifa) {
      formulaServicio = formulaTarifa.slice();
      //Variables de tarifa y del buque
      formulaServicio = formulaServicio.replace('CANT', cantidad);
      formulaServicio = formulaServicio.replace('USD', valorTarifaUSD);
      formulaServicio = formulaServicio.replace('DWT', this.selectedVessel.BUQ_DWT);
      formulaServicio = formulaServicio.replace('GRT', this.selectedVessel.BUQ_GT);
      formulaServicio = formulaServicio.replace('LOA', this.selectedVessel.BUQ_ESLORA);
    }

    //Variable factor de arqueo
    let i = 0;
    let encontroFactorArqueo = false;
    while (i < this.factoresArqueo.length && !encontroFactorArqueo) {
      if (
        this.factoresArqueo[i].rangoGRTMin <= this.selectedVessel.BUQ_GT &&
        this.factoresArqueo[i].rangoGRTMax >= this.selectedVessel.BUQ_GT
      ) {
        formulaServicio = formulaServicio.replace('FA', this.factoresArqueo[i].factorArqueo);
        encontroFactorArqueo = true;
      }

      i++;
    }

    // Variables de parametros de sistema
    if (formulaServicio) {
      this.parametrosSistema.forEach((element) => {
        formulaServicio = formulaServicio.replace(element.nombre, element.valor);
      });
    }

    return formulaServicio;
  }

  /**
   * Cambia el estado de la recepcion de la factura del servicio a agregar
   */
  onChangeFacturaRecibida() {
    if (!this.available) {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }
    if (this.servicioAAgregar.facturaRecibida) {
      this.servicioAAgregar.facturaRecibida = false;

      this.facturaRecibidaCheckBox.nativeElement.classList.remove('checked');
      this.facturaRecibidaCheckBox.nativeElement.classList.add('unchecked');
    } else {
      this.servicioAAgregar.facturaRecibida = true;

      this.facturaRecibidaCheckBox.nativeElement.classList.remove('unchecked');
      this.facturaRecibidaCheckBox.nativeElement.classList.add('checked');
    }
  }
}
